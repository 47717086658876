import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import notionJSONToHTML from "../utils/JsonToHTML";

const ArticleWrapStyle = styled.div`
    width: 100%;
    max-width: 900px;
    margin: 60px auto;
    .breadcrumb-row {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 20px 0;
        a {
            color: var(--dark);
            font-size: 14px;
            line-height: 24px;
            :hover {
                color: var(--primary-color);
            }
        }
    }
    h1.main-title {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 40px;
    }
    .article-tags {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 40px;
        span {
            display: block;
            background-color: var(--lightGray);
            border-radius: 2px;
            font-size: 12px;
            line-height: 24px;
            padding: 0 10px;
            margin-right: 10px;
        }
    }
    .article-end {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        a {
            display: bloack;
            font-size: 16px;
            color: white;
            background-color: var(--primary-color);
            line-height: 32px;
            border-radius: 16px;
            padding: 0 24px;
            :hover {
                transform: rotate(1deg);
            }
        }
    }
    h2, h3, h4, h5 {
        margin-top: 20px;
        font-weight: bold;
    }
    p {
        color: var(--dark);
        margin-top: 10px;
    }
    img {
        width: 100%;
        margin: 20px 0;
    }
    a {
        display: block;
        margin: 20px 0;
        word-wrap: break-word;
    }
    .video-block {
        width: 100%;
        padding: 20px 0;
        video {
            width: 100%;
        }
    }
    .pdf-block {
        width: 100%;
        iframe {
            width: 100%;
            height: 600px;
            border: 1px solid var(--lightGray);
        }
        p {
            margin-top: 10px;
            a {
                display: inline-block;
                font-weight: bold;
                color: var(--primary-color);
                margin-left: 10px;
            }
        }
    }
    .article {
        width: 100%;
        margin-bottom: 40px;
    }
    @media (max-width: 480px) {
        padding: 0 20px;
        margin: 0;
        h1.main-title {
            font-size: 32px;
        }
    }
`

export default function SinglePostPage({ pageContext, location }) {
    //console.log("---------");
    //console.log(location.state.fromHome);
    const { post } = pageContext;
    const postTags = [post.properties.publicTime.value.start]
    post.properties.TypeTags.value.forEach(tag => {
        postTags.push(tag.name)
    })

    const postData = JSON.parse(post.json);
    const htmlString = notionJSONToHTML(postData);

    const isFromHome = (location.state && location.state.fromHome)

    return <div>
        <ArticleWrapStyle>
            <div className="breadcrumb-row">
                <Link to={isFromHome ? `/#${post.id}` : "/resource"}>返回{isFromHome ? "首页" : "资源中心"}</Link>
            </div>
            <h1 className="main-title">{post.title}</h1>
            <div className="article-tags">
                {postTags.map(tag => (
                    <span key={tag}>{tag}</span>
                ))}
            </div>
            <div className="article">
                <div dangerouslySetInnerHTML={{__html: htmlString}} />
            </div>
            <div className="article-end">
                <Link to={isFromHome ? `/#${post.id}` : "/resource"}>返回{isFromHome ? "首页" : "资源中心"}</Link>
            </div>
        </ArticleWrapStyle>
    </div>
}