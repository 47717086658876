function handleText(textObject) {
    const textData = textObject.map(textNode => {
        return textNode.text.content;
    })
    return textData
}

function handleParagraph(paragraph) {
    if (paragraph.text.length === 0) {
        // console.log("br");
        return `<br />`;
    } else {
        const plainText = handleText(paragraph.text);
        return `<p class="color-text ${paragraph.color}">${plainText}</p>`;
    }
}

function handleImageLink(image) {
    if (image.file) {
        return image.file.url;
    } else {
        return image.external.url
    }
}

export default function notionJSONToHTML(postData) {
    const blocksObject = postData.children;
    // console.log(blocksObject);

    let htmlString = "";

    blocksObject.forEach(block => {
        switch (block.type) {
            case 'paragraph': {
                htmlString += handleParagraph(block.paragraph);
                break;
            }
            case 'heading_1': {
                const plainText = handleText(block.heading_1.text);
                htmlString += `<h2 class="color-text ${block.heading_1.color}" id="${block.id}">${plainText}</h2>`;
                break;
            }
            case 'heading_2': {
                const plainText = handleText(block.heading_2.text);
                htmlString += `<h3 class="color-text ${block.heading_2.color}" id="${block.id}">${plainText}</h3>`;
                break;
            }
            case 'heading_3': {
                const plainText = handleText(block.heading_3.text);
                htmlString += `<h4 class="color-text ${block.heading_3.color}" id="${block.id}">${plainText}</h4>`;
                break;
            }
            case 'video': {
                const videoLink = block.video.external.url;
                // console.log("It's video.");
                htmlString += `<div class="video-block" id="${block.id}"><video controls src="${videoLink}"></video></div>`;
                break;
            }
            case 'image': {
                // console.log("It's image.");
                const imageLink = handleImageLink(block.image);
                htmlString += `<div class="image-block" id="${block.id}"><img src="${imageLink}" alt="${block.image.caption}" /></div>`;
                break;
            }
            case 'pdf': {
                // console.log("It's pdf file.");
                const pdfLink = block.pdf.external.url;
                htmlString += `<div class="pdf-block" id="${block.id}"><p>保存<a href="${pdfLink}" target="_blank">PDF文档</a>。</p><iframe src="${pdfLink}#toolbar=0&view=fitH" width="100%" height="100%"></iframe></div>`;
                break;
            }
            default: {
                console.log(`Sorry, we are out of ${block.type}.`);
            }
        }
    })

    return htmlString;
}